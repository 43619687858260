<template>
  <div id="app">
    <CookieChoice></CookieChoice>
    <Header msg="Header"/>
    <HomePage 
    :tipoutilizzo="tipoutilizzo"
    msg="Body"/>
    <Footer msg="Footer"/>
  </div>

</template>

<script>
/*componenti*/
import CookieChoice from '../../components/CookieChoice.vue'
import Header from '../../components/Header.vue'
import HomePage from '../../components/HomePage.vue'
import Footer from '../../components/Footer.vue'


export default {
  name: 'app',
  components: {
    Header,
    HomePage,
    Footer,
    CookieChoice,
  },
  constants: {
    HTMLTITLE: 'SCTOutil.VDA',
    NODE_ENV: process.env.NODE_ENV
  },
  data() {
      return {
        tipoutilizzo:'noauth',
        pageGA:'/default/index',
      }},
  mounted(){
    
    //start bootstrap
    var recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', '/SCTProfessional/static/node_modules/startbootstrap-modern-business/vendor/jquery/jquery.min.js');
    document.head.appendChild(recaptchaScript);
    recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', '/SCTProfessional/static/node_modules/startbootstrap-modern-business/vendor/bootstrap/js/bootstrap.bundle.min.js');
    document.head.appendChild(recaptchaScript);
  }
}

</script>


