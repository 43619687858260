import 'core-js';
import Vue from 'vue';

/*import bootstrap*/
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);



//CSS
//boostrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//custom css sct-outil
import '@/css/sctprofessional.css';
//fontawasome
import '@fortawesome/fontawesome-free/css/all.min.css';

import App from './App.vue'

window.$ = require('jquery')
window.JQuery = require('jquery')

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
